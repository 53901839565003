import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import { useMutation } from '@apollo/react-hooks';
import { FORGOT_PASSWORD } from 'devoapi/authentication';
import {
  Content,
} from './ForgotPassword.style';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button/Button';
import { Title, Label } from 'styles/Global';
import { Field } from 'styles/Form';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { validateEmail } from 'helper/user';
import Log from 'helper/monitoring';

const ForgotPassword: React.FC = () => {
  const router = useRouter();
  const { authDispatch } = useAuth();

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [sendForgotPassword, { loading }] = useMutation(FORGOT_PASSWORD);

  const validate = (): boolean => {
    if (email.length === 0) {
      setError('Please enter an email.');
      return true;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      return true;
    }

    return false;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(null);

    if (validate()) return;

    try {
      const { data, errors } = await sendForgotPassword({
        context: { headers: { Accept: '' }},
        variables: { email: email },
      });

      if (errors) {
        setError(errors);
        Log.error('Error response from forgot password', 'auth', errors);
      } else {
        setSuccess(true);
      }
    } catch (error) {
      setError(error);
      Log.error('Error caught from forgot password', 'auth', error);
    }
  };

  const handleReturn = (e: any) => {
    e.preventDefault();
    authDispatch({ type: 'SET_FORM', payload: 'SIGNIN' });
  };

  if (success) {
    return (
      <Content>
        <Title>Forgot Password</Title>

        <Label>Please check your inbox for an email with instructions on how to reset your password.</Label>
  
        <Button onClick={handleReturn} loading={loading} type="submit" large block>
          Continue
        </Button>
      </Content>
    );
  }

  return (
    <Content>
      <Title>Forgot Password</Title>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Field
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email"
          />
        </Form.Group>

        <Button loading={loading} type="submit" large block>
          Submit
        </Button>

        {error && <ErrorMessage error={error} />}
      </Form>

      <Button style={{ margin: '20px 0'}} onClick={handleReturn} loading={loading} variant="link" block>
        Cancel
      </Button>
    </Content>
  );
};

export default ForgotPassword;