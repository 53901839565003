import React from 'react';
import styled from 'styled-components';
import parseError from 'helper/error';

export const ErrorMessageKey = {
  NOT_PARTNER: 'NOT_PARTNER'
};

const Label = styled.span`
  margin: 15px 0;
  display: block;
  color: red;
`;


type ErrorProps = {
  error: any;
};

const ErrorMessage: React.FC<ErrorProps> = ({ error }) => {

  if (error === ErrorMessageKey.NOT_PARTNER) {
    return (
      <Label>
        You are not registered as a Devo partner. Please <a href="https://devo.co.uk">contact us</a> for more info.
      </Label>
    );
  }

  return (
    <Label>
      { parseError(error) }
    </Label>
  );
};

export default ErrorMessage;