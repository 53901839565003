import styled from 'styled-components';

export const Content = styled.div`
  width: 500px;

  h1 {
    margin-bottom: 16px;
  }

  #forgotPassword {
    text-align: right;
    button {
      padding: 8px 0;
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
  }
`;