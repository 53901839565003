import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useAuth from 'contexts/auth/auth.context';
import { useLazyQuery } from '@apollo/react-hooks';
import { SIGN_IN } from 'devoapi/authentication';
import {
  Content,
} from './SignIn.style';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button/Button';
import { Title } from 'styles/Global';
import { Field } from 'styles/Form';
import ErrorMessage, { ErrorMessageKey } from 'components/ErrorMessage/ErrorMessage';
import { parseUserDataFromJwt } from 'helper/user';
import { HOME_ROUTE } from 'constants/navigation';

const SignIn: React.FC = () => {
  const router = useRouter();
  const redirect = router.query?.redirect?.toString();
  const { authDispatch } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const [signIn, { loading, data }] = useLazyQuery(SIGN_IN, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      if (response?.auth?.authToken && response?.auth?.refreshToken) {
        const userData = parseUserDataFromJwt(response.auth.authToken);
        if (userData?.shopUuid) {
          authDispatch({ type: 'AUTH_SUCCESS', payload: response.auth });
          router.push(redirect || HOME_ROUTE);
        } else {
          setError(ErrorMessageKey.NOT_PARTNER);
        }
      } else {
        setError('There was an error signing you into Devo. Please try again.');
      }
    },
    onError: error => setError(error)
  });

  const validate = (): boolean => {
    if (email.length === 0) {
      setError('Please enter an email.');
      return true;
    }

    if (password.length === 0) {
      setError('Please enter a password.');
      return true;
    }

    return false;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setError(null);

    if (validate()) return;

    const body = {
      email: email,
      authToken: password,
      authType: 'DEVO'
    };

    try {
      signIn({ variables: { input: body } });
    } catch (error) {
      setError(error);
    }
  };
  
  const handleForgotPassword = (e: any) => {
    e.preventDefault();
    authDispatch({ type: 'SET_FORM', payload: 'FORGOT' });
  }

  return (
    <Content>
      <Title>Login</Title>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Field
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email"
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Field
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="Enter password"
            autoComplete="on"
          />
        </Form.Group>

        <Form.Group id="forgotPassword">
          <Button onClick={handleForgotPassword} variant="link">Forgot Password?</Button>
        </Form.Group>

        <Button loading={loading} type="submit" large block>
          Continue
        </Button>

        {error && <ErrorMessage error={error} />}
      </Form>
    </Content>
  );
};

export default SignIn;