import gql from 'graphql-tag';

export const SIGN_IN = gql`
  query signIn($input: any) {
    auth(input: $input) @rest(type: "AuthTokenModel", method: "POST", path: "/auth/login", endpoint: "as") {
      authToken
      refreshToken
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: string) {
    forgotPassword(email: $email, input: {})
    @rest(method: "POST", path: "/user/forgotPassword?email={args.email}", endpoint: "as") {
      NoResponse
    }
  }
`;