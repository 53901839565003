import React from 'react';
import Button from 'react-bootstrap/Button';
import Loader from 'components/Loader/Loader';

type ButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'light' | 'link';
  large?: boolean;
  block?: boolean;
  onClick?: (e: any) => void;
  style?: any;
  children?: any;
};

const DevoButton: React.FC<ButtonProps> = props => {
  if (props.block) {
    return (
      <div className="d-grid">
        <Wrapper {...props} />
      </div>
    )
  } else {
    return <Wrapper {...props} />
  }
};

const Wrapper: React.FC<ButtonProps> = ({
  type,
  loading = false,
  disabled = false,
  variant = 'primary',
  large = false,
  block = false,
  onClick,
  style,
  children,
}) => (
  <Button
    type={type}
    size={large ? 'lg' : 'sm'}
    variant={variant}
    disabled={disabled || loading}
    onClick={!loading ? onClick : null}
    style={style}
  >
    { loading ? (
      <Loader color='#007bff' style={{ margin: '0 auto' }} />
    ) : (
      children
    )}
  </Button>
)

export default DevoButton;
